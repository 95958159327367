.nerds-history__slide {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px;
}

.nerds-history__slide-imagedata {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 30%;
	img{
		border-radius: 5px;
	}
}

.nerds-history__slide-imagedata p:first-of-type {
	font-weight: 600;
	font-size: 30px;
	display: flex;
	align-items: center;
	text-align: center;
	height: 90px;
}

.nerds-history__slide-imagedata p:nth-of-type(2) {
	font-weight: 500;
	font-size: 44px;
	height: 40px;
}

.nerds-history__slide-text {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 800px;
	padding: 0px 70px;
	p {
		font-size: 20px;
		padding-top: 90px;
	}
}