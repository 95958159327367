.card-container {
    width: 266px;
    border-radius: 25px;
    padding: 5%;
    background-color: #FFFFFF;
    border: 5px solid #FFFFFF;
    transition: .5s;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.profile-picture {
    border-radius: 50%;
    width: 160px;
    height: 160px;
    object-fit: cover;
    margin-bottom: 20px;
}


.card-name {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
}

.position {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 20px;
}

.linkedIn_icon {
    width: 35px;
    height: 35px;
    background-color: rgb(188, 182, 255);
    border-radius: 25%;
    margin-bottom: 20px;
}

.description {
    text-align: left;
    font-size: 18px;
}

.bio-modal {
    background-image: url('../../../assets/background.png');
    margin: 10%;
    padding: 2%;
    height: 80%;
    width: 80%;
}

.close-icon {
    cursor: pointer;
}