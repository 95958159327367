    .contact-form{
        background: linear-gradient(90deg, rgba(249, 199, 223, 0.30) 0%, rgba(250, 196, 240, 0.30) 6.25%, rgba(241, 193, 250, 0.30) 12.5%, rgba(223, 191, 251, 0.30) 18.75%, rgba(205, 189, 251, 0.30) 25%, rgba(186, 186, 252, 0.30) 31.25%, rgba(184, 200, 252, 0.30) 37.5%, rgba(182, 214, 253, 0.30) 43.75%, rgba(181, 227, 253, 0.30) 50%, rgba(179, 239, 254, 0.30) 56.25%, rgba(178, 250, 254, 0.30) 62.5%, rgba(177, 254, 248, 0.30) 68.75%, rgba(176, 255, 240, 0.30) 75%, rgba(175, 255, 234, 0.30) 81.25%, rgba(174, 255, 230, 0.30) 87.5%, rgba(174, 255, 227, 0.30) 93.75%, rgba(174, 255, 226, 0.30) 100%);
    }

    .contact-form {
        max-width: 900px; /* Set the maximum width of the form */
        margin: 0 auto; /* Center the form horizontally */
        padding: 30px; /* Add some padding around the form */
        background-color: #f9f9f9; /* Set the background color of the form */
        border-radius: 10px; /* Add border radius to give the form rounded corners */
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        height: 100vh; /* Set the height to 100% of the viewport height */
    }
    
    .contact-form form {
        display: flex;
        flex-direction: column;
        width: 800px; /* Set a fixed width for the form */
    }
    
    .contact-form label,
    .contact-form textarea,
    .contact-form button {
        margin-bottom: 10px; /* Add spacing between elements */
    }

    .contact-form input {
        width: 300px;
        margin-bottom: 10px;
    }
    
    .contact-form button {
        padding: 10px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        width: 200px;
    }
    
    .contact-form button:hover {
        background-color: #0056b3;
    }

    .error {
        border: 1px solid red;
    }

    .valid {
        border: 1px solid green;
    }

    .error-message {
        color: red;
        font-size: 14px;
    }
    .error-message {
        color: red;
        font-size: 14px;
    }

    .input-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px; /* Adjust spacing between input containers */
    }

    .input-container label {
        margin-bottom: 5px; /* Adjust spacing between label and input */
    }

    .character-counter {
        font-size: 12px;
        color: #888;
        margin-top: 5px; /* Add some space between textarea and counter */
    }

    .down-component {
        position: fixed; /* Position the component relative to the viewport */
        bottom: 20px; /* Distance from the bottom of the viewport */
        left: 50%; /* Position the component horizontally in the center */
        transform: translateX(-50%); /* Center the component horizontally */
        z-index: 1000; /* Ensure the component is above other elements */
    }

    