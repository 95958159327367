/* Caterpillar.css */
section {
  margin-bottom: 5rem;
}

#hero-section .bg-image {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.32) 13.99%, rgba(0, 0, 0, 0.00) 26.42%, rgba(0, 0, 0, 0.00) 77.46%, rgba(0, 0, 0, 0.32) 85.19%), url('../../../assets/home/hero.webp'), no-repeat;
  background-size: cover;
  height: 90vh;
}

#hero-section .text-container {
  max-width: 600px;
  height: 75vh;
  margin: 0 auto;
}


/* Style for the caterpillar container */
.donate {
  .mission-title {
    font-family: 'Inter';
    font-weight: bold;
    font-size: 100px;
    color: #000000;
    letter-spacing: -3%;
  }


  #backimage-container .background-image {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.32) 13.99%, rgba(0, 0, 0, 0.00) 26.42%, rgba(0, 0, 0, 0.00) 77.46%, rgba(0, 0, 0, 0.32) 85.19%), url('../../../assets/background.png'), no-repeat;
    background-size: cover;
    height: 90vh;
  }


  .text-center {
    text-align: center;
    margin-top: 50px;
    font-family: 'Inter';
    margin: 0 auto;
    max-width: 800px;
    font-size: 25px;
    /* Adjust the maximum width to your preference */
  }

  .paragraph-row {
    margin-top: 2px;
  }

  .inter-font {
    font-family: 'Inter';
    /* You can also specify the font-weight here if needed */
    font-weight: 400;
  }


  .paragraph-content {
    margin-top: 10px;
    font-size: 20px;
    text-align: center;

    /* Adjust the font size as needed */
  }

  .paragraph-highlight {
    font-size: 30px;
    color: plum;
    font-weight: 800;
    text-align: center;
    display: inline-flex;

  }

  .content-white-wrapper {
    width: max-content;
    /* Adjust the desired width */
    max-width: 500;
    /* Adjust the maximum width if needed */
    height: 500px;
    /* Adjust the desired height */
    /* Add any other styles or properties as needed */
  }

  .logo_decs {
    display: flex;
    flex-direction:row;
    align-items:center;

    /* Center items vertically */
    margin-right: 2px;
  }

  .logo-container {
    margin-top: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .button-image {
    display: inline-block;
    width: 200px; /* Set the width of the image */
    height: 40px; /* Set the height of the image */
    background-image: url('../../../assets/show-qr-code.png'); /* Set the URL of your image */
    background-size: cover; /* Adjust as needed to fit the image */
    margin-right: 1px;
    margin-left: -40px; /* Add spacing between the image and text if needed */
}

.show-qr-code-button {
  background-color: white; /* Set the background color to white */
  color: black; /* Set the text color to black */
  border: none; /* Remove the button border */
  padding: 5px 70px; /* Adjust padding as needed */
  cursor: pointer; /* Add a pointer cursor on hover */
  display: flex; /* Make the button a flex container */
  flex-direction: column; /* Stack the image and text vertically */
  align-items: center; /* Center items horizontally */
}




  .image-with-text {
    text-align: center;
    display: flex;
    margin: 0 20px;
    flex-direction: column;
    align-items: center;
    /* Center-align the text */
    margin-top: -30px;
    font-size: 10px;
  }


  .image-container {
    margin-top: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .image {
    width: 400px;
    /* Set the width to a desired size */
    height: 300px;
    /* Set the height to match the width for uniformity */
    margin-right: 2px;
    border-radius: 10%;
    max-width: 100%;
    border: 10px solid transparent;
  }

  .paypal-logo,
  .venmo-logo,
  .cashapp-logo_new {
    width: 300px;
    /* Set a fixed width for all logos */
    height: 100px;
    /* Set a fixed height for all logos */
    margin-right: 20px;
    /* Add spacing between the logos */
    border-radius: 10%;
    /* Make the logos circular */
    transition: transform 0.3s ease-in-out;
  }

  .payment-method {
    position: relative;
  }

  .qr-code {
    position: absolute;
    bottom: -120px;
    /* Adjust the distance from the image */
    left: 50%;
    /* Center the QR code horizontally */
    transform: translateX(-50%);
    display: none;
  }

  .logo_decs:hover .show-qr-code-button .qr-code {
    display: block;
}


}