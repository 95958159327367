section {
  margin-bottom: 5rem;
}
.icons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20rem; 
  }
  
  .icons-container img {
    border-radius: 50%;
    width: 250px;
    height: 250px;
    margin-top: 10rem;
  }
  
  .icons-container .title {
    font-size: 35px;
    font-weight: 500;
    text-align: center;
    display: block;
  }
  .book-club,.Academic-Tutoring {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers content vertically */
    align-items: center; /* Centers content horizontally */
    padding: 20px;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
    margin: 2px; /* Centers element horizontally within the page */
    border: none;
  }
  .book-club img,
  .Academic-Tutoring img {
    margin-bottom: 10px; /* Adds space between image and heading */
    width: 700px;
    height: 600px;
  }
  .book-club h1,
  .Academic-Tutoring h1 {
    margin-bottom: 5px; /* Adds space between heading and paragraph */
    text-align: center; /* Centers text within the heading */
  }
  .book-club p,
  .Academic-Tutoring p {
    line-height: 1.5; /* Improves readability of paragraph text */
    text-align: justify; /* Justifies paragraph text for better reading experience */
    margin-bottom: 2px;
  }
  