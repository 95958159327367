.nerds-history__slide {
	display: flex;
	justify-content: space-evenly;
	margin-left: 30px;
	margin-right: 30px;
}

.nerds-history__slide-imagedata {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 30%;
}

.nerds-history__slide-imagedata p:first-of-type {
	font-weight: 600;
	font-size: 30px;
	height: 50px;
}

.nerds-history__slide-imagedata p:nth-of-type(2) {
	font-weight: 500;
	font-size: 44px;
	height: 40px;
}

.nerds-history__slide-text {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 800px;
	padding: 0px 70px;
	p {
		font-size: 20px;

	}
}