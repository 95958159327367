.about-us-top {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 958px;
	width: 100%;
	background-color: #bcb6ff54;
}

.about-us h1, .about-us__meet-the-team {
	padding-top: 40px;
	padding-bottom: 40px;
	color: black;
}

.about-us-top__text {
	margin-top: 65px;
	text-align: center;
}
.about-us-top__text p {
	width: 700px;
}

.about-us-top__text button {
	width: 223px;
	height: 64px;
	background: linear-gradient(
		76.92deg,
		#f9c7df 8.44%,
		#d599ec 66.85%,
		#a259ff 87.98%
	);
	padding: 0px;
	color: black;
	font-weight: 500;
	margin-top: 40px;
    border-radius: 12px;
    border:0px;
}

.about-us__team {
	display: flex;
    justify-content: space-between;
	margin-left: 202px;
	margin-right: 202px;
}

.coach_tee {
	width: 160px;
}

.lily_maynard {
	width: 160px;
	height: 160px;
	border-radius: 50%;
}

.nerds-history__slide {
	display: flex;
  margin-left: 44px;
  margin-right: 44px;
}

.nerds-history__slide-imagedata {
	display: flex;
	align-items: center;
	flex-direction: column;
  width: 40%;
}

.nerds-history__slide-imagedata p:first-of-type{
  font-weight: 700;
  font-size: 60px;
}

.nerds-history__slide-imagedata p:nth-of-type(2) {
  font-weight: 500;
  font-size: 44px;
}

.nerds-history__slide-imagedata img {
  width: 400px;
  height: auto;
}

.nerds-history__slide-text {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 879px;
	padding: 0px 109px;
}

.year-image-class img {
	/* Your CSS styles here */
	width: 180px; /* Example width */
	height: auto; /* Example height */
}
